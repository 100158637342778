import React, { useState, useEffect } from "react"
import posed from 'react-pose';
import styles from "./header.module.scss"
import MainMenu from "./mainMenu"
import CustomLink from "./customLink"


const Word = posed.div({
	active: {
		staggerChildren: 100,
		// marginLeft: '2rem',
		// marginRight: '3rem',
		opacity: 1.0,
	},
	disabled: {
		// applyAtEnd: { display: "none" },
		// marginLeft: '0rem',
		// marginRight: '0rem',
		opacity: 1.0
	},
	hidden: {
		// applyAtEnd: { display: "none" },
		// marginLeft: '0rem',
		// marginRight: '0rem',
		opacity: 0.3
	}
});
const Letter = posed.span({
	active: {
		applyAtStart: { display: "inline-block" },
		opacity: 1,
		
		width: 'auto',
		transition: { type: 'spring' }
	},
	disabled: {
		applyAtEnd: { display: "none" },
		opacity: 0,
		width: 0,
	},
	hidden: { //igual que disabled
		applyAtEnd: { display: "none" },
		opacity: 0,
		width: 0,
	}
});

const Header = all => {
	const [letteractive, setLetteractive] = useState(0)
	const { lang, isHome, isInternal, currentPath, versions, onMenuOpenOrClose } = all;
	// const [oldsize, setOldsize] = useState(0)
	const nextLetter = () => {
		//nsole.log('letteractive')
		//onsole.log(letteractive)

		//onsole.log(currentLetteractive)
		setLetteractive(letteractive => {
			let currentLetteractive = letteractive + 1
			if(currentLetteractive > 4) currentLetteractive = 0
			return currentLetteractive
		})
	}
	
	useEffect(() => {
		const interval = window.setInterval(nextLetter, 3000)
		return () => {window.clearInterval(interval)};
	}, []);

	const [isMenuOpen, setIsMenuOpen] = useState(false)

	const updateMenuStatus = (status) => {
		setIsMenuOpen(status)
		onMenuOpenOrClose(status)
	}

	const disabledOrHidden = !isHome || letteractive > 3 || letteractive < 1 ? 'disabled' : 'hidden'
	const letter1Pose = isHome && letteractive === 1 ? 'active' : disabledOrHidden
	const letter2Pose = isHome && letteractive === 2 ? 'active' : disabledOrHidden
	const letter3Pose = isHome && letteractive === 3 ? 'active' : disabledOrHidden
	return (

		<header
			className={`${styles.header_container} ${isInternal ? styles.header_container_internal : ''} ${isMenuOpen ? styles.header_container_is_open : ''}`}
		>
			<div className={styles.header_container__content}>
				<div className={styles.backgroundWhite} />
				<CustomLink
					disabled={!isInternal}
					style={!isInternal ? {
						pointerEvents: 'none',
						cursor: 'default'
					} : {}}
					to="/"
				><h1 onClick={() => setLetteractive(letteractive+1)}><Word
					pose={ letter1Pose }
				><strong>L</strong><em><Letter>u</Letter><Letter>g</Letter><Letter>a</Letter><Letter>r</Letter></em></Word><Word
				pose={ letter2Pose }
			><strong>A</strong><Letter>r</Letter><Letter>q</Letter><Letter>u</Letter><Letter>i</Letter><Letter>t</Letter><Letter>e</Letter><Letter>c</Letter><Letter>t</Letter><Letter>u</Letter><Letter>r</Letter><Letter>a</Letter><br /></Word><Word
			pose={ letter3Pose }
		><strong>P</strong><Letter>a</Letter><Letter>i</Letter><Letter>s</Letter><Letter>a</Letter><Letter>j</Letter><Letter>e</Letter></Word></h1>
				<h2>Arquitectos <br />asociados</h2>
				</CustomLink>
				<MainMenu
					lang={lang}
					isInternal={isInternal}
					currentPath={currentPath}
					onChangeMenuStatus={updateMenuStatus}
					versions={versions}
				/>
			</div>
		</header>
	)
}

export default Header
