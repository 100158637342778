const date = new Date();
const year = date.getFullYear();

export const translations = {
	
	cookiesAlertAccept: {
		es: 'Aceptar',
		en: 'Accept cookies'
	},
	cookiesAlertCancel: {
		es: 'Rechazar',
		en: 'Reject'
	},
	footerCopyright: {
		es: ` © ${year}. Lugar Arquitectura y Paisaje Arquitectos Asociados SLP. Todos los derechos reservados.`,
		en: ` © ${year}. Lugar Arquitectura y Paisaje Arquitectos Asociados SLP. All rights reserved.`,
	}
}