import React, { useState, useEffect, useRef } from "react"
// import * as THREE from 'three';
// import { NoiseEffect, EffectComposer, EffectPass, RenderPass, BlendFunction } from "postprocessing"
import { graphql, useStaticQuery } from "gatsby"
import Img from 'gatsby-image/withIEPolyfill'
import _ from 'lodash'
import Simple1DNoise from '../helpers/Simple1DNoise';

import "../styles/background-slide.scss"
import styles from "./background-slide.module.scss"
const cameraNoiseGeneratorX = new Simple1DNoise()
const cameraNoiseGeneratorY = new Simple1DNoise()




const BackgroundSlides = (all) => {
	const isSSR = typeof window === "undefined"
	const data = useStaticQuery(graphql`
	query{
		allPwPages(filter: {page_template: {eq: "INICIO"}}) {
			edges {
				node {
					title
					lang
					background_images_horizontal {
						title
						movimiento
						tiempo
						description
						image_url
						image {
							childImageSharp {
								fluid(maxWidth: 2240, quality: 40, base64Width: 25) {
									...GatsbyImageSharpFluid_withWebp
									aspectRatio
									presentationWidth
									presentationHeight
								}
							}
						}
					}
					background_images_vertical {
						title
						movimiento
						tiempo
						description
						image_url
						image {
							childImageSharp {
								fluid(maxWidth: 2240, quality: 40, base64Width: 25) {
									...GatsbyImageSharpFluid_withWebp
									aspectRatio
									presentationWidth
									presentationHeight
								}
							}
						}
					}
				}
			}
		}
	}
	`)
	const { lang, isMenuOpen } = all
	const startPage = data.allPwPages.edges.find(page => {
		return page.node.lang === lang
	})
	const imagesVertical = startPage ? startPage.node.background_images_vertical : []
	const imagesHorizontal = startPage ? startPage.node.background_images_horizontal : []
	const allImages = [
		{
			name: 'horizontal',
			images: imagesHorizontal
		},
		{
			name: 'vertical',
			images: imagesVertical
		}
	]
	const animProps = useRef({
		hasStarted: {
			horizontal: false,
			vertical: false,
		},
		// oldTimestamp: 99999,
		startTimestamp: 0,
		activeImage: {
			horizontal: 0,
			vertical: 0,
		},
	});
	
	const nextImage = (orientationProp) => {
		const orientation = orientationProp ? orientationProp : (window.innerWidth >= window.innerHeight ? 'horizontal' : 'vertical')
		let netxImageNumber = animProps.current.activeImage[orientation] + 1
		if(netxImageNumber+1 > _.find(allImages, {name: orientation }).images.length){
			netxImageNumber = 0
		}
		changeImage(netxImageNumber, orientation) 
	}
	const prevImage = (orientationProp) => {
		const orientation = orientationProp ? orientationProp : (window.innerWidth >= window.innerHeight ? 'horizontal' : 'vertical')
		let netxImageNumber = animProps.current.activeImage[orientation] - 1
		if(netxImageNumber <= -1 ){
			netxImageNumber = _.find(allImages, {name: orientation }).images.length - 1
		}
		changeImage(netxImageNumber, orientation) 
	}
	const changeImage = (imageNumber, orientationProp) => {
		const orientation = orientationProp ? orientationProp : (window.innerWidth >= window.innerHeight ? 'horizontal' : 'vertical')
		animProps.current.activeImage[orientation] = imageNumber
		animProps.current.startTimestamp = 0
		hideAllImagesExcept()
	}

	const hideAllImagesExcept = (imageNumber, orientationProp) => {
		const orientation = orientationProp ? orientationProp : (window.innerWidth >= window.innerHeight ? 'horizontal' : 'vertical')
		const allImagesElem = document.querySelectorAll(`.${styles[orientation]} .${styles.image}`)
		_.forEach(allImagesElem,(imageElem, index) => {
			if(
				index === animProps.current.activeImage[orientation] - 1 ||
				index === animProps.current.activeImage[orientation] + 1
				){
				imageElem.classList.add(styles.image_visible)
			} else {
				imageElem.classList.remove(styles.image_visible)
			}
			if(index === animProps.current.activeImage[orientation]){
				imageElem.classList.add(styles.image_active)
			} else {
				imageElem.classList.remove(styles.image_active)
			}
		})
		const allCationsElem = document.querySelectorAll(`.${styles[orientation]} .${styles.captions_item}`)
		_.forEach(allCationsElem, captionElem => {
			captionElem.classList.remove(styles.caption_visible)
		})
		updateSizeAndPosition()
	}
 
	const updateSizeAndPosition = (orientationProp) => {
		const orientation = orientationProp ? orientationProp : (window.innerWidth >= window.innerHeight ? 'horizontal' : 'vertical')
		const imagesElement = document.querySelectorAll(`.${styles.container}.${styles[orientation]} .${styles.image}`)
		if(imagesElement.length>0){
			animProps.current.hasStarted[orientation] = true
		}
		_.forEach(imagesElement, (imageElem, index) => {
			const imageContentElem = imageElem.querySelector(`.${styles.image_content}`)
			const realImageWidth = parseInt(imageElem.getAttribute('datawidth'))
			const realImageHeight = parseInt(imageElem.getAttribute('dataheight'))
			const realImageProportion = realImageWidth / realImageHeight
			const windowProportion = window.innerWidth / window.innerHeight
			if(realImageProportion > windowProportion) { //imagen más ancho que la ventana
				let width = parseInt(window.innerHeight * realImageProportion)
				if (width < window.innerWidth) width = window.innerWidth
				let height = width / realImageProportion
				imageContentElem.style.width = Math.ceil(width) + 'px'
				imageContentElem.style.height = Math.ceil(height) + 'px'
			} else {
				let height = window.innerWidth / realImageProportion
				if (height < window.innerHeight) height = window.innerHeight
				let width = height * realImageProportion
				imageContentElem.style.width = Math.ceil(width) + 'px'
				imageContentElem.style.height = Math.ceil(height) + 'px'
			}
		})
	}

	const animate = (timestamp) => {
		const orientation = window.innerWidth >= window.innerHeight ? 'horizontal' : 'vertical'
		if(timestamp !== 0){
			// document.querySelector('.logClass').innerHTML = timestamp - animProps.current.oldTimestamp
			// animProps.current.oldTimestamp = timestamp
			const { activeImage, startTimestamp } = animProps.current
			if(startTimestamp === 0) {
				animProps.current.startTimestamp = timestamp
			}
			const time = timestamp - animProps.current.startTimestamp
			const activeImageElem = document.querySelector(`.image_${orientation}_${activeImage[orientation]}`);
			const activeImageContentElem = activeImageElem.querySelector(`.${styles.image_content}`)

			const activeImageObj = _.find(allImages, {name: orientation }).images[activeImage[orientation]]
			// if(activeImageElem){
			// 	activeImageElem.classList.add(styles.image_active)
			// }
			//caption
			const activeCationElem = document.querySelector(`.caption_${orientation}_${activeImage[orientation]}`);
			if(activeCationElem){
				activeCationElem.classList.add(styles.caption_visible)
			}

			const frame = activeImageObj.tiempo * 1000;
			const porUno = time / frame
			const offsetHeight = (activeImageContentElem.offsetHeight - window.innerHeight) / 2
			const offsetWidth =  (activeImageContentElem.offsetWidth - window.innerWidth) / 2
			let posX = 0
			let posY = 0
			switch (activeImageObj.movimiento) {
				case 2: //➡️
					posX = - offsetWidth + (porUno * offsetWidth * 2)
					break;
				case 3: //⬅️
					posX = offsetWidth - (porUno * offsetWidth * 2)
					break;
				case 4: //⬆️
					posY = offsetHeight - (porUno * offsetHeight * 2)
					break;
				case 5: //⬇️
					posY = - offsetHeight + (porUno * offsetHeight * 2)
					break;
				default:
					break;
			}
			activeImageElem.style.transform = `translate(${posX}px, ${posY}px)`

			if(time > frame){
				nextImage()
			}
		}
		if(!animProps.current.hasStarted[orientation]){
			updateSizeAndPosition()
		}
		window.requestAnimationFrame(animate)
	}
	if (!isSSR) {
		animate(0)
		
		window.addEventListener('resize', () => { updateSizeAndPosition() }) ;

	}

	
	return allImages.map(imageGroup => {
		let isThisOrientationActiveOnLoad = false
		if(!isSSR){
			if(window.innerWidth >= window.innerHeight && imageGroup.name === 'horizontal'){
				isThisOrientationActiveOnLoad = true
			} else if(window.innerWidth < window.innerHeight && imageGroup.name === 'vertical'){
				isThisOrientationActiveOnLoad = true
			}
		}
		return (
		
			<div className={`${styles.container} ${styles[imageGroup.name]}`} key={imageGroup.name}>
				{/* <div 
					className="logClass"
					style={{
						position: 'fixed',
						left: 0,
						bottom: 0,
						padding: '20px',
						backgroundColor: 'white',
						zIndex: 100
					}}
				/> */}
				<div
					className={styles.images_container}
				>
					{
						imageGroup.images.map((image, index) => (
							<div
								className={`${styles.image} image_${imageGroup.name}_${index} ${index==0 ? styles.image_active : ''} ${index==1 ? styles.image_visible : ''}`}
								key={index}
								datawidth={image.image.childImageSharp.fluid.presentationWidth}
								dataheight={image.image.childImageSharp.fluid.presentationHeight}
							>
								<div
									className={`${styles.image_content}`}
								>
									<Img
										fluid={image.image.childImageSharp.fluid}
										className={styles.image_gatsby}
										alt={image.description}
										loading={isThisOrientationActiveOnLoad ? "eager" : "lazy"}
										backgroundColor="#666666"
										objectFit="cover"
										durationFadeIn={1500}
										// objectPosition={`${objectPosition.x}% ${objectPosition.y}%`}
									/>
									</div>
							</div>
						))
					}
					
				</div>
				<div className={styles.captions}>
					<div className={`${styles.controls} ${isMenuOpen ? styles.control__menu_open : ''}`}>
						<span onClick={() => prevImage(imageGroup.name)}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="17"
								height="20"
								fill="none"
								viewBox="0 0 17 20"
							>
								<path
									fill="#fff"
									d="M15.343 19.854L.656 10.998C.219 10.779 0 10.451 0 10.014c0-.389.219-.729.656-1.02L15.343.137c.316-.17.669-.182 1.057-.036.39.121.584.425.584.911 0 .413-.195.741-.584.984L3.134 9.941 16.4 17.886c.39.243.584.571.584.984 0 .438-.207.765-.62.984-.389.195-.729.195-1.02 0z"
								></path>
							</svg>
						</span>
						<span onClick={() => nextImage(imageGroup.name)}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="17"
								height="20"
								fill="none"
								viewBox="0 0 17 20"
							>
								<path
									fill="#fff"
									d="M1.64 19.854c-.292.195-.644.195-1.057 0C.194 19.636 0 19.308 0 18.87c0-.413.194-.74.583-.984L13.85 9.941.583 1.996C.194 1.753 0 1.426 0 1.012 0 .526.194.222.583.101c.389-.146.741-.134 1.057.036l14.687 8.857c.438.291.656.631.656 1.02 0 .437-.218.765-.656.984L1.64 19.854z"
								></path>
							</svg>
						</span>
					</div>
					<div className={styles.caption_texts}>
						{
							imageGroup.images.map((image, index) => (
								<div key={index} className={`${styles.captions_item} caption_${imageGroup.name}_${index}`}>
									<h3>{image.title}</h3>
									<h4>{image.text}</h4>
								</div>
							))
						}
					</div>
				</div>
			</div>)
	})
}

export default BackgroundSlides