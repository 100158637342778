/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useState} from "react"
import CookieConsent from "react-cookie-consent";

// import BackgroundSlides from "../components/background-slide"
import { translations } from "../lang/translations"
import Header from "./header"
import Footer from '../components/footer'

import "../styles/layout.scss"
import styles from  "./layout.module.scss"
import CustomLink from "./customLink";

import BackgroundSliders from './background-sliders-imperative'
// const BackgroundSliders = loadable(() => import('./background-sliders-imperative'))


const Layout = (all) => {
	const { children, pageContext } = all
	const pageTemplate = pageContext.page_template
	const { lang, page_template, page_url } = pageContext
	//onsole.log('pageContext')
	//onsole.log(pageContext)
	const isInternal = page_template !== 'INICIO'
	//El current path sirve para marcar el menú principal activo. Así que enviamos en page_url si esta página es de nivel 1 o 0 (el padre es home o es home) o el page_url de su padre.
	const currentPath = pageContext.parentpage && pageContext.parentpage.pwid !== 1 ? pageContext.parentpage.page_url : page_url;

	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const onMenuOpenOrClose = (isOpen) => {
		setIsMenuOpen(isOpen)
		//onsole.log(isMenuOpen)
	}

	return (
		<div className={`${styles.container} ${!isInternal ? 'layout-is-home' : ''}`}>
			{/* <BackgroundSlides lang={lang} /> */}
			<BackgroundSliders
				lang={lang}
				isMenuOpen={isMenuOpen}
			/>	
			<Header
				lang={lang}
				isInternal={isInternal}
				isHome={pageTemplate === 'INICIO'}
				currentPath={currentPath}
				versions={pageContext.versions}
				onMenuOpenOrClose={onMenuOpenOrClose}
			/>
			
			
			<div className={styles.content}>{children}</div>
			<Footer
				lang={lang}
				isInternal={false}
			/>
			<CookieConsent
				location="none"
				buttonText={translations.cookiesAlertAccept[lang]}
				enableDeclineButton
				declineButtonText={translations.cookiesAlertCancel[lang]}
				cookiesAlertAccept
				cookieName="gatsby-gdpr-google-analytics"
				containerClasses={styles.cookiesContainer}
				buttonStyle={{ color: "white",  background: "black" }}
				declineButtonStyle={{ color: "white", background: "rgba(180,0,0)" }}
				expires={150}
				disableStyles={true}
		>
				{lang == 'es' ? 
				<div>Este sitio web utiliza cookies propias y de terceros para el correcto funcionamiento y visualización del sitio web por parte del usuario, así como la recogida de estadísticas tal y como se recoge en la política de cookies en la columna "finalidad". Si continúa navegando, consideramos que acepta su uso. Puede cambiar la configuración u obtener más información visitando nuestra <CustomLink to='/politica-cookies/'>Política de Cookies</CustomLink>.</div>
				:
				<div>This website uses its own and third-party cookies for the correct operation and visualization of the website by the user, as well as the collection of statistics as set out in the cookies policy in the "purpose" column. By continuing to use this website, you agree to the use of cookies. You can change the settings or get more information <CustomLink to='/cookies-policy/'>here</CustomLink>.</div>
				}
				{/* {translations.cookiesAlertText[lang]} */}
		</CookieConsent>
		</div>
	)
}


// Mobile height vh fix
const calculateVH = () => {
	let vh = 1
	//onsole.log("Calculate")
	if(typeof window === 'object'){
		vh = window.innerHeight * 0.01
		// vh = vh < 5.16 ? 5.16 : vh
		document.documentElement.style.setProperty('--vh', `${vh}px`)
	}
}
calculateVH();
if(typeof window === 'object'){
	window.addEventListener('resize', () => {
		calculateVH();
	});
}


export default Layout
