import React from "react"
import styles from "./footer.module.scss"
import CustomLink from "./customLink"
import { graphql, StaticQuery } from "gatsby"
import _ from 'lodash'
import { translations } from "../lang/translations"



const Footer = all => {
	const { lang, isInternal } = all;


	return(
		<StaticQuery
			query={graphql`
				query {
					allSitePage(filter: {context: {page_template: {eq: "LEGAL"}}}){
						edges {
							node {
								path
								context {
									title
									page_url
									page_template
									lang
								}
							}
						}
					}
				}
			`}
			
			render={data => {
				//onsole.log(data.allSitePage.edges)
				const pagesLegal = _.filter(data.allSitePage.edges, {node: { context: { lang: lang}}})
				// const pagesLegal = _.filter(data.allSitePage.edges, {node: { context: { lang: lang, page_template: 'LEGAL'}}})

				return(
					<footer
						className={`${styles.container} ${isInternal ? styles.container_internal : ''}`}
					>	<div>
							<div>
								{pagesLegal.map((page, key) => (
									<div key={key}>
										<CustomLink
											to={page.node.context.page_url}
											isInternalLink={true}
										>{page.node.context.title}</CustomLink>
									</div>
									)
								)}
								
							</div>
							<div className={styles.copyright}><span>{translations.footerCopyright[lang]}</span></div>
						</div>
					</footer>
					
				)}
			}
		/>
		
	)

}

export default Footer
