import React, { useState } from "react"
import {useSpring, animated as anim} from 'react-spring'
// import { useSiteMetadata } from "../hooks/sitePages"
import { graphql, StaticQuery } from "gatsby"
import _ from 'lodash'
import { useWindowSize } from "../helpers/screenSizeDetector"

import styles from "./mainMenu.module.scss"
import CustomLink from "./customLink"



const MainMenu = all => {
	const { lang, currentPath, isInternal, onChangeMenuStatus, versions } = all
	const [isOpen, setIsOpen] = useState(false)
	const [isOver, setIsOver] = useState(false)
	
	const windowSize = useWindowSize()

	// useEffect(() => {
	// }, [])
	
	const onMenuPress = () =>{
		onChangeMenuStatus(!isOpen)
		setIsOpen(!isOpen)
	}
	const onMouseEnter = () =>{
		setIsOver(true)
	}
	const onMouseLeave = () =>{
		setIsOver(false)
	}
	const closeMenuIfMobile = () => {
		if(windowSize === 'mobile'){
			onChangeMenuStatus(false)
			setIsOpen(false)
		}
	}

	// componentDidUpdate(prevProps, prevState) {
  //   if (this.props.isInternal !== prevProps.isInternal) {
	// 		this.setState({
	// 			isOpen: false,
	// 			isInternal: this.props.isInternal,
	// 		});
  //   }
  // }
	
	
	//onsole.log(this)
	let menuState = ''
	if(windowSize === 'mobile'){
		if(isOpen){
			menuState = 'opened'
		} else {
			menuState = 'closed'
		}
	} else {
		if(isOpen || isInternal){
			menuState = 'opened'
		} else {
			if(isOver){
				menuState = 'over'
			} else {
				menuState = 'closed'
			}
		}
	}

	const menuSpring = useSpring({
		numberTop: menuState === 'over' ? 1.2 : ( menuState === 'opened' ? 3.2 : 0.8 ),
		width: menuState !== 'opened' ? '5rem' : '22.0rem',
		menuSecOpacity: menuState !== 'opened' ? 0 : 1,
	})
	
	return(
		<StaticQuery
			query={graphql`
				query {
					processwire {
						langs {
							code
							title
						}
					}
					allSitePage(filter: {context: {page_template: {eq: "GENERAL"}}}) {
						edges {
							node {
								path
								context {
									title
									page_url
									page_template
									lang
									parentpage{
										pwid
										page_url
										title
									}
								}
							}
						}
					}
				}
			`}
			
			render={data => {
				const pagesNivel1 = _.filter(data.allSitePage.edges, {node: { context: { lang: lang, parentpage: { pwid: 1 }}}})
				const langs = data.processwire.langs
				return(
					<div
						className={`${styles.container} ${isInternal ? styles.containerInternal : ''}`}
					>
						<div
							// pose={menuState}
							className={styles.menu}
						>
							<ul>
								{pagesNivel1.map((page, key) => {
									//onsole.log(page)
									const isActive = currentPath===page.node.context.page_url;
									return (
										<anim.li
											// withParent={false}
											//pose={currentPath===page.node.context.page_url ? 'active' : 'init'}
											//index={key}
											style={{
												top: menuSpring.numberTop.interpolate(numberTop => `${numberTop * key}rem`),
												width: menuSpring.width
											}}
											key={key}
										>
											<CustomLink
												onClick={() => closeMenuIfMobile()}
												withParent={false}
												to={page.node.context.page_url}
												isInternalLink={true}
												className={isActive ? styles.active : ''}
											><span /> <strong>{page.node.context.title}</strong></CustomLink>
										</anim.li>
									)}
								)}
							</ul>
							<anim.div
								className={styles.menuSec}
								style={{
									opacity: menuSpring.menuSecOpacity,
								}}
							>
								<div className={`${styles.langs} ${isOpen ? styles.langs__isopen : ''}`}>
									{/* <span>ES</span> | <CustomLink to="/">EN</CustomLink><br /> */}
									{langs.map((langObject, key) => {
										if(langObject.code === lang ){
											return (
												<span
													key={key}
												>{langObject.code}</span>
											)
										} else {
											if(_.find(versions, {lang: langObject.code})){
												return (
													<CustomLink
														key={key}
														to={_.find(versions, {lang: langObject.code}).page_url}
													>{langObject.code}</CustomLink>
												)
											} else {
											return null
											}
										}
									})}
								</div>
								
								
							</anim.div>
							<div
								className={`${styles.boton} ${isOpen ? styles.botonIsOpen : ''} ${isInternal ? styles.botonIsInternal : ''}`}
								onClick={onMenuPress}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}
							/>
						</div>
						
					</div>
				)}
			}
		/>
		
	)
}


export default MainMenu