import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"

const transitions = {
	// default: {
	// 	exit={{
	// 		length: 1,
	// 		trigger: ({ exit, node }) =>
	// 			{
	// 				//onsole.log('exit')
	// 				//onsole.log({ exit, node, direction: "out" })
	// 			}
	// 	}}
	// 	entry={{
	// 		length: 0.1,
	// 		// delay: duration,
	// 		trigger: ({ exit, node }) =>
	// 		{
	// 			//onsole.log('entri')
	// 			//onsole.log({ exit, node, direction: "out" })
	// 		}
	// 	}}
	// }
	default: {
		exit:{
			length: 1,
		},
		entry:{
			length: 0.1,
			// trigger: (data) => {
			//onsole.log('data');
			//onsole.log(data);
			// }
		}
	},
}
// const bodyToHome = () => {
//onsole.log("bodyToHome")
// 	document.body.classList.remove('isInternal')
// }
// const bodyToInternal = () => {
//onsole.log("bodyToInternal")
// 	document.body.classList.add('isInternal')
// }

const CustomLink = React.forwardRef(({ to, title, children, className, transitionName, style, onClick }, ref) => {
	let transition;
	if(transitionName){
		transition = transitions[transitionName];
	} else {
		transition = transitions.default;
	}
	// if(isInternalLink){
	// 	transition.entry.trigger = bodyToInternal
	// } else {
	// 	transition.entry.trigger = bodyToHome
	// }


	//onsole.log(transition);
	return (
		<TransitionLink
			onClick={onClick}
			ref={ref}
			className={className}
			to={to}
			title={title}
			exit={transition.exit}
			entry={transition.entry}
			style={style}
		>
			{children}
		</TransitionLink>
	)
})
export default CustomLink;